var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticStyle:{"overflow":"scroll"},attrs:{"flat":_vm.flat,"disabled":_vm.disabled}},[_c('v-card-title',{class:!_vm.title ? 'pt-0 pb-0' : ''},[_vm._t("header",function(){return [(_vm.icon)?_c('v-icon',{attrs:{"left":"","color":"primary"},domProps:{"textContent":_vm._s(_vm.icon)}}):_vm._e(),_vm._v(" "+_vm._s(_vm.title)+" "),_c('v-spacer'),_vm._t("actions")]},{"title":_vm.title,"icon":_vm.icon})],2),_c('v-card-text',[_vm._t("default",function(){return [_c('SimpleTable',{attrs:{"columns":_vm.dataListColumns,"items":_vm.dataListItems,"item-key":_vm.dataListItemKey,"loading":_vm.loading},scopedSlots:_vm._u([{key:"appendHead",fn:function(){return [_vm._t("appendHead")]},proxy:true},{key:"appendBody",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [_vm._t("appendBody",null,{"item":item,"index":index})]}},{key:"bodyContent",fn:function(ref){
var column = ref.column;
var propName = ref.propName;
var item = ref.item;
var index = ref.index;
return [_vm._t("bodyContent",null,{"column":column,"propName":propName,"item":item,"index":index})]}}],null,true)})]})],2),_vm._t("footer")],2)}
var staticRenderFns = []

export { render, staticRenderFns }