

































import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import { LoaderComponent } from 'vue-loading-overlay';
import { ISimpleTableColumn } from '@/interfaces/simple-table-column.interface';

@Component({})
export default class SimpleTable extends Vue {
  loader: LoaderComponent | null = null;

  @Prop({ required: true })
  readonly columns!: ISimpleTableColumn[];

  @Prop()
  readonly items!: [];

  @Prop()
  readonly itemKey!: string;

  @Prop({ type: [String, Boolean], default: undefined })
  readonly emptyMessage!: string | boolean;

  @Prop()
  readonly loading!: boolean;

  @Watch('loading')
  onLoadingChange(active: boolean): void {
    this.setLoader(active);
  }

  mounted(): void {
    this.setLoader(this.loading);
  }

  getItemKey(index: number): string | number {
    return this.itemKey || index;
  }

  get sanitizedColumns(): ISimpleTableColumn[] {
    return this.columns.filter((x) => !x.hide);
  }

  get columnWidth(): string[] {
    return this.columns.map((column) => column.width || 'auto');
  }

  get minColumnWidth(): string[] {
    return this.columns.map((column) => column.minWidth || column.width || 'auto');
  }

  get showEmptyMessage(): boolean {
    const showMessage = Boolean(this.emptyMessage) || this.emptyMessage === undefined;
    const hasItems = this.items && !this.items.length;
    return showMessage && hasItems;
  }

  get emptyItemsText(): string {
    return this.emptyMessage ? `${this.emptyMessage}` : 'Nenhum registro encontrado';
  }

  private setLoader(active: boolean): void {
    if (active) {
      this.loader = this.$loading.show({
        container: this.$refs.tableContainer,
        canCancel: false,
      });
    } else if (this.loader) {
      this.loader.hide();
    }
  }
}
