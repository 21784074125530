



















import { Vue, Component, Prop } from 'vue-property-decorator';
import { ActivityEventStatusEnum } from '@/enums/crm/activity-event-status.enum';
import dayjs from '@/plugins/dayjs';
import { ICalendarEvent } from '@/interfaces/crm/calendar-event.interface';

@Component({})
export default class CrmActivityCalendarEvent extends Vue {
  @Prop({ required: true })
  event!: ICalendarEvent;

  get eventDate(): string {
    if (!this.event) {
      return '';
    }

    let formattedDate = this.$d(this.event.start, this.event.timed ? 'dateTime' : 'short');

    if (this.event.end) {
      const isSameDate = dayjs(this.event.start).isSame(this.event.end);
      formattedDate += !isSameDate ? ` - ${this.$d(this.event.end, this.event.timed ? 'dateTime' : 'short')}` : '';
    }

    return formattedDate;
  }

  get eventClosed(): boolean {
    return this.event?.status === ActivityEventStatusEnum.Closed;
  }

  get headerColor(): string {
    return this.event?.color || 'primary';
  }

  get cnpj(): string {
    return this.event.cnpj === this.event.idProspect?.toString()
      ? ''
      : `${this.event.cnpj} - ` || '';
  }
}
