





import { Vue, Component, Prop } from 'vue-property-decorator';
import { inject } from 'inversify-props';
import ProcessService from '@/services/crm/process.service';
import { InjectionIdEnum } from '@/enums/injection-id.enum';
import { ClientTypeEnum } from '@/enums/client-type.enum';

@Component({})
export default class CrmNewKanbanViewer extends Vue {
  @inject(InjectionIdEnum.CrmProcessService)
  private processService!: ProcessService;

  @Prop({ required: true })
  readonly flowId!: number;

  @Prop({ required: true })
  readonly clientId!: string;

  @Prop({ required: true })
  readonly clientType!: ClientTypeEnum;

  @Prop()
  readonly defaultQueue!: number;

  @Prop()
  readonly processId!: number;

  async mounted(): Promise<void> {
    let loader;
    try {
      loader = this.$loading.show({
        container: this.$refs.kanbanViewerContainer,
        canCancel: false,
      });
    } catch (err) {
      this.$notify.error(err && (err as Error).message);
    } finally {
      loader.hide();
    }
  }

  get kanbanUrl(): string {
    return this.processService.getNewKanbanUrl(
      this.flowId,
      this.clientId,
      this.clientType,
      this.defaultQueue,
      this.processId,
      true,
    );
  }
}
