

















































import { padStart } from 'lodash';
import { Vue, Component, Prop, VModel, Watch } from 'vue-property-decorator';

@Component({})
export default class TimePickerField extends Vue {
  timeFieldModel = '';

  fieldMaskActive = false;

  menu: boolean | null = null;

  @VModel({ required: true })
  model!: string | undefined;

  @Prop()
  readonly rules!: (CallableFunction | unknown[])[];

  @Prop()
  label!: string;

  @Prop({ default: false })
  readonly solo!: boolean;

  @Prop({ default: false })
  dense!: boolean;

  @Prop({ default: false })
  hideDetails!: boolean | string;

  @Prop({ default: 0 })
  minuteStep!: number;

  @Prop({ default: null })
  min!: string;

  @Prop({ default: null })
  max!: string;

  @Prop({ default: false })
  disabled!: boolean;

  @Watch('model', {
    deep: true,
    immediate: true,
  })
  onModelChange(value: string): void {
    this.timeFieldModel = value;
  }

  onTimeFieldChange(): void {
    if (this.timeFieldModel) {
      const regex = RegExp(/^([0-1]\d|2[0-3]):[0-5]\d$/gi);
      const isValid = regex.test(this.timeFieldModel);

      if (!isValid) {
        this.timeFieldModel = this.model || '';
      }

      if (this.minuteStep && this.timeFieldModel) {
        const splittedTime = this.timeFieldModel.split(':');
        const minute = parseInt(splittedTime[1], 10);
        const diff = minute % this.minuteStep;

        if (diff !== 0) {
          const roundReference = Math.floor(this.minuteStep / 2);
          const roundedNumber = roundReference <= diff ? minute + this.minuteStep - diff : minute - diff;

          this.timeFieldModel = `${splittedTime[0]}:${padStart(roundedNumber.toString(), 2, '0')}`;
        }
      }
    }

    this.model = this.timeFieldModel;
    this.$emit('change', this.model);
  }

  onTimeFieldBlur(): void {
    this.menu = false;
  }

  allowedStep(minutes: number): boolean {
    if (this.minuteStep) {
      return minutes % this.minuteStep === 0;
    }

    return true;
  }
}
