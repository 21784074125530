import { Vue } from 'vue-property-decorator';
import { i18n } from '@/i18n-setup';

type VueComponent = Vue & {
  validate: () => boolean;
  resetValidation: () => void;
  reset: () => void;
};

export abstract class FormHelper {
  static validate(form: Vue, bubbleError = true): boolean {
    const formComponent = form as VueComponent;
    const validate = formComponent.validate();

    if (!validate && bubbleError) {
      Vue.prototype.$notify.error(i18n.t('global.invalidForm'));
    }

    return validate;
  }

  static resetValidation(form: Vue): void {
    const formComponent = form as VueComponent;
    formComponent.resetValidation();
  }

  static reset(form: Vue): void {
    const formComponent = form as VueComponent;
    formComponent.reset();
  }
}
