



















import { Vue, Component, Prop, VModel, Watch, Emit } from 'vue-property-decorator';
import Tooltip from '@/components/tooltip.vue';

@Component({
  components: {
    Tooltip,
  },
})
export default class DataGridFilter extends Vue {
  @Prop({ default: -1 })
  openFilter!: number;

  @VModel({ default: false })
  model!: boolean;

  @Watch('model', { immediate: true })
  onModelChange(value: string): void {
    this.openFilter = value ? 0 : -1;
  }

  @Prop({ default: 'global.dataTableFilter.title' })
  title!: string;

  @Prop()
  counter!: number;

  @Prop({ default: 'global.dataTableFilter.clickHereToFilterItems' })
  openTooltipMessage!: string;

  @Prop({ default: 'global.dataTableFilter.clickToHideFilters' })
  closeTooltipMessage!: string;

  @Emit('change')
  onOpenFilterChange(value: number): void {
    this.model = value === 0;
  }

  get filterTooltipMessage(): string {
    return `${this.$t(this.openFilter === 0 ? this.closeTooltipMessage : this.openTooltipMessage)}`;
  }
}
