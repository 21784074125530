



























import { Vue, Component, Prop } from 'vue-property-decorator';
import { ISimpleTableColumn } from '@/interfaces/simple-table-column.interface';
import SimpleTable from '@/components/simple-table.vue';

@Component({
  components: {
    SimpleTable,
  },
})
export default class CardDataList extends Vue {
  @Prop()
  readonly title!: string;

  @Prop()
  readonly icon!: string;

  @Prop({ default: [] })
  readonly dataListColumns!: ISimpleTableColumn[];

  @Prop()
  readonly dataListItems!: unknown[];

  @Prop()
  readonly dataListItemKey!: string;

  @Prop()
  readonly loading!: boolean;

  @Prop({ default: false })
  readonly flat!: boolean;

  @Prop({ default: false })
  readonly disabled!: boolean;

  @Prop()
  readonly classes!: string;
}
