



































import { Vue, Component, Emit, VModel, Prop, Watch } from 'vue-property-decorator';
import { UploadResult, Uppy, UppyFile } from '@uppy/core';
import { DragDrop, ProgressBar } from '@uppy/vue';
import XHRUpload from '@uppy/xhr-upload';
import { cloneDeep, random } from 'lodash';
import { inject } from 'inversify-props';
import { LoaderComponent } from 'vue-loading-overlay';
import PtBr from '@uppy/locales/lib/pt_BR';
import { IKeyValue } from '@/interfaces/key-value.interface';
import { InjectionIdEnum } from '@/enums/injection-id.enum';
import SessionService from '@/services/session.service';
import { IUploadedFile } from '@/interfaces/uploaded-file.interface';
import dayjs from '@/plugins/dayjs';
import { StringHelper } from '@/utils/helpers/string-helper';

@Component({
  components: {
    DragDrop,
    ProgressBar,
  },
})
export default class DragDropUpload extends Vue {
  @inject(InjectionIdEnum.SessionService)
  protected sessionService!: SessionService;

  files: IUploadedFile[] = [];

  uploading: string[] = [];

  uppyInstance: Uppy | null = null;

  showUploader = false;

  private loader: LoaderComponent | null = null;

  @VModel()
  dialog!: boolean;

  @Watch('dialog')
  onModelChange(active: boolean): void {
    if (active) {
      this.uppyInstance = this.getUppyInstance();
      this.showUploader = true;
    } else {
      setTimeout(() => {
        this.showUploader = false;
      }, 450);
    }
  }

  @Prop({ required: true })
  uploadEndpoint!: string;

  @Prop({ required: true })
  clientId!: string;

  @Prop()
  place!: string;

  @Prop()
  hash!: string;

  @Prop()
  title!: string;

  @Emit()
  afterClose(): IUploadedFile[] {
    const response = cloneDeep(this.files);
    this.files = [];
    this.uppyInstance?.reset();
    return response;
  }

  onDeleteFile(file: IUploadedFile): void {
    this.files = this.files.filter((x) => x.uploadId !== file.uploadId);
    this.$emit('delete-files', [file]);
  }

  onClose(): void {
    if (this.uploading.length) {
      this.$notify.info(this.$t('global.dragDropUpload.waitUntilUploadFinishing'));
      return;
    }

    this.dialog = false;
  }

  onCancel(): void {
    if (this.uploading.length) {
      this.uppyInstance?.cancelAll();
    }

    const filesToRemove = cloneDeep(this.files);

    this.files = [];

    this.dialog = false;

    this.$emit('delete-files', filesToRemove);
  }

  beforeDestroy(): void {
    if (this.uppyInstance) {
      this.uppyInstance.close();
      this.uppyInstance = null;
    }
  }

  setBusyLoader(): void {
    if (!this.loader) {
      this.loader = this.$loading.show({
        container: this.$refs.dragDropContainer,
        canCancel: false,
      });
    }
  }

  unsetBusyLoader(): void {
    if (!this.uploading.length && this.loader) {
      this.loader.hide();
      this.loader = null;
    }
  }

  get dialogTitle(): string {
    return this.title || `${this.$t('global.dragDropUpload.title')}`;
  }

  private onUploadStart(data: { id: string }): void {
    this.uploading.push(data.id);
    this.setBusyLoader();
  }

  private onUploadComplete(result: UploadResult): void {
    const { uploadID } = result as unknown as IKeyValue;
    this.uploading = this.uploading.filter((x) => x !== uploadID);
    this.unsetBusyLoader();

    result.successful.forEach((file) => {
      const { id, name, size } = file;

      if (!this.files.some((x) => x.uploadId === id)) {
        const body = file.response?.body || {};
        const { filename, path } = ((body.files || []) as Array<IKeyValue>).find((x) => x.filename === name) || {};

        this.files.push({
          uploadId: id,
          filename,
          path,
          parsedSize: DragDropUpload.parseSize(size),
        });
      }

      this.uppyInstance?.removeFile(file.id);
    });

    this.$notify.success(this.$t('global.dragDropUpload.uploadSuccessfulyFinished'), 1000);

    this.uppyInstance?.resetProgress();
  }

  private onUploadError(file: UppyFile, error: Error): void {
    this.$notify.error(error);
  }

  private getUppyInstance(): Uppy {
    if (this.uppyInstance) {
      this.uppyInstance.close();
      this.uppyInstance = null;
    }

    const uppy = new Uppy({
      locale: PtBr,
      id: `${dayjs().format()}${random(1, 15)}`,
      autoProceed: false,
      onBeforeFileAdded: (currentFile) => {
        let curFile = currentFile;
        // verifica se o nome do arquivo tem letras com acentuação e substitui pela letra 'X'
        if (currentFile.name.match(/[^\w/_/./-]/gi)) {
          curFile = {
            ...currentFile,
            name: StringHelper.formatFilename(currentFile.name),
          };
        }
        return curFile;
      },
    })
      .use(XHRUpload, {
        id: `${dayjs().format()}${random(1, 15)}`,
        endpoint: this.uploadEndpoint,
        headers: {
          Authorization: `Bearer ${this.sessionService.apiToken}`,
        },
        responseType: 'text',
        method: 'POST',
        fieldName: 'files',
        formData: true,
        bundle: true,
        timeout: 0,
        getResponseError: (responseText: string) => {
          let errorMessage = responseText;
          if (!responseText) {
            errorMessage = `${this.$t('global.dragDropUpload.errorOnUpload')}`;
          }
          return new Error(errorMessage);
        },
      })
      .on('upload', this.onUploadStart)
      .on('upload-error', this.onUploadError)
      .on('complete', this.onUploadComplete);

    uppy.setOptions({
      id: `${dayjs().format()}${random(1, 15)}`,
      autoProceed: true,
    });

    uppy.setMeta({
      clientId: this.clientId,
      place: this.place || '',
      hash: this.hash || '',
    });

    return uppy;
  }

  static parseSize(size: number): string {
    let parsedSize = '';
    const mbFactor = 1048576;
    const kbFactor = 1024;

    if (size >= mbFactor) {
      parsedSize = `${Math.round(size / mbFactor)}MB`;
    } else if (size >= kbFactor) {
      parsedSize = `${Math.round(size / kbFactor)}KB`;
    } else {
      parsedSize = `${size}bytes`;
    }

    return parsedSize;
  }
}
