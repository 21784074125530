import { get, has, isArray, omit, pick, set, unset } from 'lodash';
import { IKeyValue } from '@/interfaces/key-value.interface';

export interface IPickOption {
  pick?: string[];
  ignore?: string[];
}

export abstract class ObjectHelper {
  static mapObjectValues<T = IKeyValue<unknown>>(
    object: unknown,
    pickList?: string[] | IPickOption | null,
    formatters = {},
  ): T {
    let newObject: unknown = {};

    // If pickList is present, pick or omit values
    if (pickList) {
      const propertiesToPick = isArray(pickList) ? pickList : pickList.pick;
      const propertiesToIgnore = !isArray(pickList) ? pickList.ignore : null;

      if (propertiesToPick && propertiesToPick.length) {
        newObject = pick(object as Record<string, unknown>, ObjectHelper.parsePaths(object, propertiesToPick));
      }

      if (propertiesToIgnore && propertiesToIgnore.length) {
        newObject = omit(object as Record<string, unknown>, ObjectHelper.parsePaths(object, propertiesToIgnore));
      }
    } else {
      newObject = object;
    }

    if (formatters) {
      ObjectHelper.formatObjectValues(newObject, formatters);
    }

    return newObject as T;
  }

  private static parsePaths(valuesObject: unknown, paths: string[]): string[] {
    let mappedPaths: string[] = [];

    paths.forEach((path) => {
      const arrayBracketsPosition = path.indexOf('[*]');

      if (arrayBracketsPosition > -1) {
        const intermediaryPath = path.substr(0, arrayBracketsPosition);

        if (has(valuesObject, intermediaryPath)) {
          const value = get(valuesObject, intermediaryPath);

          if (value instanceof Array && value.length) {
            const intermediaryPaths: string[] = [];
            for (let index = 0; index < value.length; index += 1) {
              const newPath = path.replace('[*]', `[${index}]`);
              intermediaryPaths.push(newPath);
            }

            mappedPaths = [...mappedPaths, ...ObjectHelper.parsePaths(valuesObject, intermediaryPaths)];
          }
        }
      } else {
        mappedPaths.push(path);
      }
    });

    return mappedPaths;
  }

  private static formatObjectValues(valuesObject: unknown, formatters = {}) {
    const formatterKeys = Object.keys(formatters);

    formatterKeys.forEach((path) => {
      const arrayBracketsPosition = path.indexOf('[*]');

      if (arrayBracketsPosition > -1) {
        const intermediaryPath = path.substr(0, arrayBracketsPosition);

        if (has(valuesObject, intermediaryPath)) {
          const value = get(valuesObject, intermediaryPath);

          if (value instanceof Array && value.length) {
            const newMapping = [];

            for (let index = 0; index < value.length; index += 1) {
              const newPath = path.replace('[*]', `[${index}]`);
              newMapping[newPath] = formatters[path];
            }

            // Delete path with [*]
            unset(formatters, path);

            ObjectHelper.formatObjectValues(valuesObject, newMapping);
          }
        }
      } else if (has(valuesObject, path)) {
        // Get value of path
        const value = get(valuesObject, path);

        // Call formatter function passing value to be be formatted
        set(valuesObject as IKeyValue, path, formatters[path](value));
      }
    });
  }
}
