import { TranslateResult } from 'vue-i18n';
import { i18n } from '@/i18n-setup';

export abstract class ValidationRules {
  // #region Misc

  static required(value: unknown, fieldName: string): boolean | TranslateResult {
    return !!value || value === 0 || i18n.t('global.validations.required', { fieldName });
  }

  // #endregion

  // #region String

  static email(value: string, fieldName: string): boolean | TranslateResult {
    const validate = !value || /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value);
    return validate || i18n.t('global.validations.email', { fieldName });
  }

  static phone(value: string, fieldName: string): boolean | TranslateResult {
    const validate = !value || /\(\b([0-9]{2})\)?\s*[0-9]{4,5}-[0-9]{4}\b/gm.test(value);
    return validate || i18n.t('global.validations.phone', { fieldName });
  }

  static cnpj(value: string, fieldName: string, ...args: unknown[]): boolean | TranslateResult {
    if (!value && !args[0]) return true;
    let validate;
    if (!value) {
      validate = false;
    } else if (value.length === 18) {
      // cnpj, 18 pois conta com os caracteres
      validate = !value || /[0-9]{2}\.[0-9]{3}\.[0-9]{3}\/[0-9]{4}-[0-9]{2}/gm.test(value);
    } else if (value.length === 14) {
      // cpf
      validate = !value || /[0-9]{3}\.[0-9]{3}\.[0-9]{3}-[0-9]{2}/gm.test(value);
    }
    return validate || i18n.t('global.validations.cnpj', { fieldName });
  }

  static minLength(value: string, fieldName: string, ...args: unknown[]): boolean | TranslateResult {
    const minLength = (args[0] || 1) as number;
    const validate = !!value && value.length >= minLength;
    return validate || i18n.tc('global.validations.minLength', minLength, { fieldName });
  }

  static maxLength(value: string, fieldName: string, ...args: unknown[]): boolean | TranslateResult {
    const maxLength = (args[0] || 1) as number;
    const validate = !value || value.length <= maxLength;
    return validate || i18n.tc('global.validations.maxLength', maxLength, { fieldName });
  }

  // #endregion

  // #region Array

  static arrayOfEmails(value: string[], fieldName: string): boolean | TranslateResult {
    const validate = !value || value.every((x) => /\S+@\S+\.\S+/.test(x));
    return validate || i18n.t('global.validations.arrayOfEmails', { fieldName });
  }

  static arrayLength(value: unknown[], fieldName: string, ...args: unknown[]): boolean | TranslateResult {
    const length = (args[0] || 1) as number;
    const validate = !!value && value.length === length;
    return validate || i18n.tc('global.validations.arrayLength', length, { fieldName });
  }

  static minArrayLength(value: unknown[], fieldName: string, ...args: unknown[]): boolean | TranslateResult {
    const minLength = (args[0] || 1) as number;
    const validate = !!value && value.length >= minLength;
    return validate || i18n.tc('global.validations.minArrayLength', minLength, { fieldName });
  }

  static maxArrayLength(value: unknown[], fieldName: string, ...args: unknown[]): boolean | TranslateResult {
    const maxLength = (args[0] || 1) as number;
    const validate = !!value && value.length >= maxLength;
    return validate || i18n.tc('global.validations.maxArrayLength', maxLength, { fieldName });
  }

  // #endregion

  // #region File

  static fileMaxSize(value: File | File[], fieldName: string, ...args: unknown[]): boolean | TranslateResult {
    const maxSize = (args[0] || 1) as number; // in KB
    const convertedSize = maxSize * 1024; // in bytes
    let validate = false;

    if (value && value instanceof Array) {
      validate = !value.some((file) => file.size > convertedSize);
    } else {
      validate = !value || value.size > convertedSize;
    }

    return validate || i18n.t('global.validations.fileMaxSize', { fieldName, maxSize });
  }

  // #endregion
}
