








import { Vue, Component, Prop } from 'vue-property-decorator';
import CrmActivityCalendar from '@/components/crm/activity-calendar.vue';
import ClientModel from '@/models/crm/client.model';
import UserContactInfo from '@/models/crm/user-contact-info.model';
import { ClientTypeEnum } from '@/enums/client-type.enum';

@Component({
  components: {
    CrmActivityCalendar,
  },
})
export default class CrmActivityView extends Vue {
  @Prop({ required: true })
  client!: ClientModel;

  @Prop({ required: true })
  clientType!: ClientTypeEnum;

  @Prop({ required: true })
  userContactInfo!: UserContactInfo;

  @Prop()
  onloadOpen!: number;
}
