




























import { Vue, Component, Prop, VModel } from 'vue-property-decorator';

type Validation = {
  [propName: string]: boolean;
};

@Component({
  inheritAttrs: true,
})
export default class MultipleEmailField extends Vue {
  @VModel({ default: () => [] })
  model!: string[];

  @Prop()
  rules!: string[];

  @Prop()
  label!: string;

  @Prop()
  disabled!: boolean;

  @Prop()
  readonly!: boolean;

  @Prop({ default: 'auto' })
  hideDetails!: boolean | string;

  @Prop({ default: true })
  highlightInvalid!: boolean;

  get closeable(): boolean {
    return !this.disabled && !this.readonly;
  }

  get invalid(): Validation {
    const validation: Validation = {};

    if (this.highlightInvalid) {
      this.model.forEach((item) => {
        validation[item] = !/\S+@\S+\.\S+/.test(item);
      });
    }

    return validation;
  }
}
