




















import { Vue, Component, Prop } from 'vue-property-decorator';

@Component({})
export default class Tooltip extends Vue {
  @Prop()
  message!: string;

  @Prop({ default: 'd-inline-block' })
  contentClass!: string;

  @Prop({ default: 500 })
  openDelay!: string | number;

  @Prop({ default: 0 })
  closeDelay!: string | number;

  @Prop()
  top!: boolean;

  @Prop()
  right!: boolean;

  @Prop()
  bottom!: boolean;

  @Prop()
  left!: boolean;
}
