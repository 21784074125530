import { Bold, Italic, Strike, Underline, BulletList, OrderedList, ListItem, HorizontalRule,
  History, HardBreak, Table, TableHeader, TableCell, TableRow, Image, Link } from 'tiptap-vuetify';

export abstract class DefaultExtensions {
  static getExtensionsWithTable(): unknown[] {
    return [History, Bold, Italic, Strike, Underline, ListItem, BulletList,
      OrderedList, HorizontalRule, HardBreak, Table, TableHeader, TableCell, TableRow, Link, Image];
  }

  static getExtensionsNoTable(): unknown[] {
    return [History, Bold, Italic, Strike, Underline, ListItem, BulletList, OrderedList, HorizontalRule,
      HardBreak, Image];
  }

  static getExtensionsFormatText(): unknown[] {
    return [History, Bold, Italic, Strike, Underline, HorizontalRule, HardBreak];
  }
}
