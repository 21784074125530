




















import { Vue, Component, Prop, VModel, Watch } from 'vue-property-decorator';
import {
  TiptapVuetify,
  Bold,
  Italic,
  Strike,
  Underline,
  BulletList,
  OrderedList,
  ListItem,
  HorizontalRule,
  History,
  HardBreak,
} from 'tiptap-vuetify';
import { EditorView } from 'tiptap';

@Component({
  components: {
    TiptapVuetify,
  },
})
export default class WysiwygEditor extends Vue {
  @VModel()
  model!: string;

  @Prop()
  rules!: CallableFunction[];

  @Prop()
  hideDetails!: string | boolean;

  @Prop()
  disabled!: boolean;

  @Prop()
  minHeight!: string | number;

  @Prop()
  maxHeight!: string | number;

  @Prop()
  placeholder!: string;

  @Prop()
  attachments!: File[];

  @Prop({
    default: () => [
      History,
      Bold,
      Italic,
      Strike,
      Underline,
      ListItem,
      BulletList,
      OrderedList,
      HorizontalRule,
      HardBreak,
    ],
  })
  extensions!: unknown[];

  @Prop({
    default: () => [],
  })
  nativeExtensions!: unknown[];

  @Watch('model')
  onModelChange(value: string): void {
    if (!value) {
      return;
    }

    // Since TipTapVuetify always mantain a empty paragraph, it is necessary to clean
    const entities = [
      ['amp', '&'],
      ['apos', '\''],
      ['lt', '<'],
      ['gt', '>'],
      ['quot', '\''],
      ['#39', '\''],
      ['#039', '\''],
    ];

    const regex = new RegExp(`&(${entities.map(([name]) => name).join('|')});`, 'g');

    let sanitizedValue = value.replace(regex, (_, entity) => {
      const match = entities.find(([name]) => name === entity);
      return match ? match[1] : entity;
    });

    sanitizedValue = sanitizedValue.replace(/<p><\/p>/gi, '');

    if (sanitizedValue !== value) {
      this.model = sanitizedValue;
    }
  }

  attachmentsHtml!: string;

  attachmentsSize = 0;

  onEvent(event: KeyboardEvent | FocusEvent, view: EditorView): void {
    this.$emit('event', { event, view });
  }
}
